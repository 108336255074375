/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        var $win = $( window );
        // JavaScript to be fired on all pages
        $('a').smoothScroll();

        var section = $('.js-section');
            
        
       var setHeight = function() {
          section.each(function() {
            var $this = $(this),
                  text = $this.find('.js-text'),
                  photo = $this.find('.js-photo');

            photo.css({height : ''});
            text.css({height : ''});
            
            if($win.width() > 990) {
              photo.height(text.outerHeight());
              text.height(text.outerHeight() - 100);
            }
          });
      };

      var row = $('.js-set-height');

      var setRowHeight = function() {
        row.each(function() {
          var $this = $(this),
              elem = $this.find('.js-row-height');
          
          elem.css({height : ''});
          if($win.width() > 990) {
            elem.height($this.height() - 100);
          }

        });
      };


      var setMapHeight = function() {
          var map = $('.js-map'),
              elem = map.find('.js-map-height');
          
          elem.css({height : ''});
          if($win.width() > 990) {
            elem.height(map.height());
          }
      };

      setMapHeight();
      setRowHeight();
      setHeight();

      $win.resize(function() {
        setMapHeight();
        setRowHeight();
        setHeight();
      });


      function initialize() {
        var chilidLocation = new google.maps.LatLng(50.1012938,19.0089467);

        var mapOptions = {
          zoom: 12,
          center: chilidLocation,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          streetViewControl: false,
          scaleControl: false,
          scrollwheel: false,
          draggable: false,
          styles: [{"featureType":"landscape","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","stylers":[{"saturation":-100},{"lightness":51},{"visibility":"simplified"}]},{"featureType":"road.highway","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"road.arterial","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},{"featureType":"road.local","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"administrative.province","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":-25},{"saturation":-100}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]}]
        };
        var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(50.1012938,19.0089467),
          map: map,
          title: 'Hello World!'
        });

        google.maps.event.addDomListener(window, 'resize', function() {
          map.setCenter(chilidLocation);
        });
      }

      google.maps.event.addDomListener(window, 'load', initialize);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
